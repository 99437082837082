
.s-column{
    width: calc(10% - 16px);
}

.si .ant-select-selector, .sf .ant-select-selector{
    border: none !important;
    /*height: 26px !important;*/
    height: 100% !important;
    display: flex;
    align-items: center;
    width: calc(100% - 4px) !important;
    margin: 0 2px !important;
}