.bd{
    border: 1px solid;
}
.bd-r{
    border-right: 1px solid;
}
.bd-l{
    border-left: 1px solid;
}
.bd-b{
    border-bottom: 1px solid;
}
.bd-t{
    border-top: 1px solid;
}

.bd-b-dash{
    border-bottom: 1px dashed;

}

.st-5{
    font-size: 5px;
}
.st-7{
    font-size: 7px;
}
.st-9{
    font-size: 9px;
}

.workplan-layout, .sf, .si{
    min-width: 1200px;
    padding: 48px 40px;
    background-color: white;
    color: #1A1311;
    height: 100%;
    overflow: auto;
}
.content-layout{
    border: 0.1rem solid #1A1311;
}
.header-layout{
    padding: 10px 20px;
    width: 100%;
}
.header-wrapper{
    height: 30px;
}
.title-wrapper{
    width: 40%;
}
.subtitle-wrapper{
    width: 50%;
    justify-content: space-between;
    align-items: flex-end;
}

.title{
    font-size: 21px;
    font-weight: 600;
}
.subtitle{
    font-size: 8px;
}


.secondary{
    color: #DC000C;
}
.date-layout{
    width: 38%;
    justify-content: flex-end;
    height: 100%;
}

.date-item-wrapper{
    justify-content: space-between;
    /*border-bottom: 1px dashed;*/
    padding-bottom: 2px;
}

.date-item{
    width: 138px;
    justify-content: space-between;
}
.small-subtitle{
    font-size: 6px;
}

.wp-ipnut .ant-select-item-option-content{
    font-size: 9px !important;
}
.workplan-layout .ant-select-selector, .sf .ant-select-selector{
    border: none !important;
    /*height: 26px !important;*/
    height: 100% !important;
    display: flex;
    align-items: center;
    width: calc(100% - 4px) !important;
    margin: 0 2px !important;
}

.workplan-layout .ant-checkbox-inner{
    width: 14px;
    height: 14px;
    border-radius: 2px;
}
.workplan-layout .ant-checkbox-wrapper{
    font-size: 7px;
    padding: 0;
    margin-right: 12px;
    margin-left: 4px;
}
.workplan-layout .ant-checkbox + span{
    padding-left: 4px;
    padding-right: 12px;
}

.checkbox-layout .ant-checkbox-wrapper{
    margin-right: 18px;
    margin-left: 12px;
}
.vt{
    font-size: 9px;
    writing-mode: tb;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    font-weight: bold;
}
.col-height{
    height: 32px !important;
}
.text-column{
    width: 50%;
}
.text-column div{
    padding: 7px 0;
}

.text-layout{
    padding: 12px;
}
.text-center{
    padding-left: 6px;
    display: flex;
    align-items: center;
}

.wp-input{
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 0 8px;
    font-size: 9px;
}
.wp-input:focus{
    outline: none;
}
textarea.ant-input.wp-text-area{
    height: 167px !important;
    padding: 8px;
    margin: 0;
}
.wp-btn{
    height: 37px;
    width: 110px;
    border-radius: 7px;
    font-size: 10px;
    font-weight: 600;
    border-color: white !important;
}

.wp-date{
    font-size: 9px;
}
.wp-date .ant-picker-input > input{
    font-size: 9px;
    font-weight: 500;

}
.m-column{
    width: calc(100%/8);
    flex-shrink: 0;
}
