.sf{
    min-width: unset;
    /*width: 820px;*/
    /*margin-left: calc(50% - 410px);*/
    /*min-width: unset;*/
}
.sf .title{
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 16px
;
}
.st-11{
    font-size: 11px;
}
.b{
    font-weight: bold;
}
.txt-f-center{
    display: flex;
    align-items: center;
    justify-content: center;
}

.label{
    width: 200px;
    display: flex;
    align-items: center;
    padding-left: 8px;
}
.full-w{
    width: 100%;
}
.full-h{
    height: 100%;
    padding: 1px;
}
.no{
    width: 40px;
    height: 32px;
}
.t-full-height{
    height: 100%;
}