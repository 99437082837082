


body {
    letter-spacing: 0.1rem;
}

.app {
    background-color: #F0F1F3;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}
.st-12{
    font-size: 12px;
}

.ant-modal-close {
    top: -16px;
    right: -16px;
}

.click {
    cursor: pointer;
}

.row {
    display: flex;
    flex-direction: row;
}

.center {
    justify-content: center;
    align-items: center;
}

.column {
    display: flex;
    flex-direction: column;
}

.appbar-layout {
    height: 80px;
    background-color: white;
}

.appbar {
    display: flex;
    padding: 0 16px;
    height: 100%;
    align-items: center;
    justify-content: space-between;

}

.logo {
    margin-right: 20px;
}

.logo img {
    height: 50px;
}

.appbar-menu {
    display: flex;

}

.appbar-btn-v {
    height: 40px;
    width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 12px;
    cursor: pointer;
    align-items: center;
}

.appbar-btn-v img {
    height: 20px;
}


.appbar-btn-v p {
    margin: 0;
    font-size: 10px;
    color: gray;
    margin-top: 6px;

}

.appbar-btn-h {
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin: 0 12px;
    cursor: pointer;
    align-items: center;
}

.appbar-btn-h img {
    height: 20px;
}

.appbar-btn-h p {
    margin: 0;
    font-size: 12px;
    color: gray;
    margin-left: 6px;
}

.site-btn {
    font-size: 10px;
    padding: 4px 12px;
    border-radius: 16px;
    margin: 0 2px;
    cursor: pointer;
}

.drawer-layout {
    width: 350px;
    height: 100%;
    background-color: white;
}

.mini-drawer {
    width: 100px;
    background-color: white;
}

.main-layout {
    width: 100%;
    height: 100%;
}

.card-layout {
    margin: 8px;
    background-color: white;
    width: 100%;
    min-height: 250px;
    border-radius: 6px;
    padding: 18px 12px;
}

.card-title {
    font-size: 13px;
    margin-left: 8px;
    font-weight: 600;
}

.divider {
    margin: 16px 0;
}

.card-btn button {
    border-radius: 6px;
    margin: 4px 0;
    height: 42px;
    justify-content: space-between;
    align-items: center;
}

.first-menu {
    align-items: center;
    margin-left: 20px;
}

.second-menu {
    align-items: center;
    margin-left: 40px;
}

.site-menu-item {
    font-size: 10px;
    font-weight: 600;
    padding: 8px 0;
}

.login-layout {
    height: 100%;
}

.login {
    padding: 32px;
    background-color: white;
    border-radius: 6px;
}

.login-logo {
    justify-content: center;
    align-items: center;
    display: flex;
}

.login-logo img {
    height: 50px;
}

.login-input {
    margin-top: 16px;
    margin-bottom: 10px;
    width: 300px;
}

.login-input input,
.add-dialog-body input {
    margin-top: 4px;
    padding: 8px 8px;
    border-radius: 4px;
}

input.error {
    border-color: #ff4d4f !important;
    background: white;
}

.input-space-box {
    height: 16px;
}

.helper-text-error {
    font-size: 12px;
    color: #ff4d4f;
    opacity: 0.8;
    /*position: absolute;*/
}

.login-input button {
    width: 100%;
    border-radius: 6px;
    height: 40px;
    font-weight: 600;
    font-size: 12px;
    background-color: #377EF3;

}

.input-title {
    font-size: 12px;
    font-weight: 600;
    margin-top: 16px;
    color: grey;
}

.add-dialog-body .input-title {
    font-size: 10px;
}

.mini-drawer .column {
    background-color: #F0F1F3;
    margin: 12px;
    border-radius: 6px;
    padding: 12px 0;
    cursor: pointer;
}

.mini-drawer img {
    width: 18px;
}

.mini-drawer .mini-title {
    font-size: 8px;
    margin-top: 4px;
}

.dialog {
    padding: 12px;
}

.dialog .title {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 14px;
}

.dialog .subtitle {
    font-size: 11px;
    font-weight: 600;
}

.dialog .divider {
    margin: 20px 0;
}

.add-dialog-footer {
    justify-content: flex-end;
    margin-top: 30px;
}

.add-dialog-footer button, .delete-dialog-footer button {
    height: 38px;
    width: 130px;
    margin-left: 12px;
    border-radius: 6px;
    border-width: 0;
    font-weight: 500;
    font-size: 12px;
}

.main-layout, .drawer-layout {
    height: calc(100vh - 119px);
    overflow-y: auto;
}

.ant-modal-content {
    border-radius: 6px;
}

.delete-dialog-body .icon {
    font-size: 80px;
    margin-top: -25px;
}

.delete-dialog-body .title {
    font-size: 15px;
    font-weight: 600;
}

.delete-dialog-body .subtitle {
    font-size: 11px;
}

.delete-dialog-footer {
    margin-top: 20px;
}

.maintenance, .ordering {
    margin: 0 32px 16px;
}

.maintenance .ant-tabs-nav, .ordering .ant-tabs-nav {
    height: 50px;
}

.maintenance .ant-tabs-tab-btn, .ordering .ant-tabs-tab-btn {
    font-size: 12px;
    font-weight: 600;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 2px solid #E9E9E9;
}


.company, .user-list {
    /*overflow-y: auto;*/
}

.company, .user-list, .system-setting, .order_info, .material-table, .site-info {
    background-color: white;
    height: calc(100vh - 168px);
    border-radius: 6px;
    position: relative;

}

.site-info {
    /*height: calc(100vh - 202px)*/
    /*height: calc(100vh - 264px)*/
    height: calc(100vh - 278px);
}

.order_info {
    min-height: 300px;
    height: 100%;
    /*margin-bottom: 4px;*/
    margin-bottom: 12px;
}


.order_info .table-wrapper {
    min-height: 199px;
    height: calc(100vh - 705px);
    overflow-y: auto;
}

.company .wrapper, .user-list .wrapper,
.system-setting .wrapper,
.order_info .wrapper, .material-table, .site-info .wrapper {
    padding: 8px 22px 0;
}

.company .title, .user-list .title, .system-setting .title,
.order_info .title, .card_header .title, .material-table .title, .site-info .title {
    margin-left: 6px;
    font-size: 12px;
    font-weight: 600;
}

.company .icon, .user-list .icon, .system-setting .icon,
.order_info .icon, .card_header .icon, .site-info .icon {
    font-size: 14px;
}

.company .header, .user-list .header, .system-setting .header,
.order_info .header, .material-table .header, .site-info .header {
    height: 46px;
    justify-content: space-between;
}

.company-icon-btn, .user-list-icon-btn {
    cursor: pointer;
    height: 46px;
    width: 44px;
    border-radius: 10px;
    background-color: #FAFAFA;
    margin-left: 8px;
    transition-property: background-color;
    transition-duration: 0.5s;
}
.icon-btn-active{
    border: 0.12rem solid #377EF3;
    background-color: #F0F1F3;
}

.company-icon-btn:hover, .user-list-icon-btn:hover {
    background-color: #F0F1F3;
}

.company-icon-btn img, .user-list-icon-btn img {
    height: 16px;
    margin-left: 2px;
}

.company-icon-btn .btn-title, .user-list-icon-btn .btn-title {
    font-size: 9px;
    margin-top: 1px;
}

.company .divider, .user-list .divider,
.system-setting .divider, .order_info .divider, .material-table .divider,
.site-info .divider {
    margin: 8px 0;

}

.table-header {
    align-items: center;
    margin-bottom: 8px;
}
.table-header div {
    font-size: 10px;
    font-weight: 600;
    color: rgb(0, 0, 0, 0.9);
    padding: 0 22px;
    height: 36px;
    display: flex;
    align-items: center;
}

.table-li {
    align-items: center;
    cursor: pointer;
    /*height: 42px;*/
}

.maintenance .table-li {
    height: 44px;
}

.selected {
    background-color: #F0F1F3 !important;
}

.table-li div {
    width: 100%;
    padding: 6px 22px;
    font-size: 12px;
}

.table-li:nth-child(even) div{
    background-color: #FAFAFA;
}

.table-li div:not(:last-child) {
    border-right: 2px solid #F7F7F7;
}

.system-setting .small-text, .site-info .small-text {
    font-size: 10px;
    color: gray;
    margin-top: -5px;
}

.site-info .small-text {
    margin-top: 0;
    margin-left: 6px;
}

.system-setting .input {
    margin-top: 12px;
}

.system-setting .input input {
    height: 36px;
    border-radius: 4px;
    width: 36%;
    margin-right: 20px;
    font-weight: 500;
    border-width: 0.1rem;
}

.system-setting .input button {
    height: 36px;
    border-radius: 4px;
    width: 100px;
    font-size: 10px;
    font-weight: 600;
}

.work {
    margin: 32px;
    height: calc(100vh - 144px);
    background-color: white;
    border-radius: 6px;
}

.work .wrapper {
    height: 100%;
    width: 100%;
    padding-bottom: 22px;
    justify-content: space-between;
}

.work .input-title {
    font-weight: 600;
    font-size: 12px;
    color: black;
    opacity: 0.8;
    margin: 8px 0;
}

.work .input-label {
    font-size: 10px;
    font-weight: 600;
    color: grey;
    margin: 4px 0;
}

.datetime-input {
    height: 64px;
    margin-top: 16px;
}

.datetime-input .input-wrapper:last-child {
    margin-top: 20px;
}

.datetime-input .connect {
    font-size: 16px;
    font-weight: bold;
    width: 120px;
    /* height: 100%; */
    text-align: center;
    margin-block-start: 20px;
}

.datetime-input .input-wrapper, .datetime-input .ant-picker {
    width: 100%;
}

.work .wrapper button {
    width: 150px;
    height: 40px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 12px;
}

.work .wrapper .btn {
    justify-content: flex-end;
}

.order_info .search, .material-table .search {
    height: 46px;
    justify-content: start;
    align-items: center;
    padding: 0 22px;
}

.order_info .search .select, .material-table .search .select {
    width: 25%;
    margin-right: 30px;

}

.search .select .ant-select-selector, .search .search-input {
    border-radius: 6px;
    height: 36px;
}


.order_info .search .search-input, .material-table .search .search-input {
    width: 35%;
    margin-left: 30px;
}

.order_info .search .checkbox, .material-table .search .checkbox {
    font-size: 10px;
    color: gray;
}

.card-wrapper {
    margin-top: 12px;
    margin-bottom: 12px;
}



.vehicle, .load-item, .travel-route, .location, .placement {
    min-height: 350px;
    background-color: white;
    width: 100%;
    border-radius: 6px;
    max-height: 600px;

}
.card{
    width: 100%;
    border-radius: 6px;
    overflow-y: auto;
    background-color: white;
    min-height: unset;
    max-height: unset;
}

.location {
    /*width: 65%;*/
    margin-right: 12px;
}

.placement {
    width: 35%;
}

.load-item {
    margin: 0 16px;
}

.card_header {
    padding: 8px 12px;
    justify-content: space-between;
}

.divider-wrapper {
    margin: 0 12px 12px;
}

.divider-wrapper .divider {
    margin: 0
}

.card-wrapper .content, .site-info .content {
    height: calc(100% - 71px);
    background-color: white;
    border-radius: 6px;
}

.travel-route .content{
height: calc(100% - 107px)
}

.site-info .content {
    /*height: calc(100vh - 265px)*/
}

.content {
    position: relative;
}

.card-wrapper .sub-icon {
    justify-content: flex-end;
    margin-bottom: 8px;
    margin-right: 12px;
}

.card-wrapper .icon-title {
    font-size: 10px;
    color: grey;
    letter-spacing: 0.08rem;
}

.select-background {
    background-color: rgb(55, 126, 243) !important;
    color: white !important;
}

.maintenance .table-wrapper {
    border-radius: 6px;
    margin-top: 6px;
    overflow: auto;
    height: calc(100vh - 239px);
}

.expand-icon {
    height: 20px;
    margin-left: 2px;
}

.expand-btn-w {
    width: 30px;
    height: 30px;
    background-color: #FAFAFA;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.3s;
    right: 12px;
    bottom: 12px;
    z-index: 1000;
}

.expand-btn-w:hover {
    background-color: #F0F1F3;
}

#map {
    height: calc(100vh - 220px)
}

.select-field {
    width: 100%;
    margin-top: 4px;
}

.select-field input {
    height: 100% !important;
}

.select-field .ant-select-selector {
    height: 42px !important;
    padding: 0 8px !important;
    border-radius: 4px !important;
}

.select-field .ant-select-selection-item {
    line-height: 40px !important;
}

.ant-picker {
    width: 100%;
    border-radius: 4px;
    margin-top: 4px;
}


.ant-picker-input > input {
    padding: 5px 12px 5px 0;
    margin-top: 0;
    height: 32px;
}

.quotation {
    padding: 5px 10px;
    height: 120px;
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-top: 4px;

    overflow-y: auto;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
}

.quotation .ant-checkbox-group {
    display: flex !important;
    flex-direction: column !important;
}

.ant-checkbox-wrapper {
    padding: 5px 10px;
}

.ant-checkbox-inner {
    border-radius: 4px;
}

.route-btn {
    background-color: white;
    padding: 12px 16px;
    color: black;
    margin-right: 12px;
    border-radius: 6px;
    font-size: 12px;
    cursor: pointer;
    border: 1px solid white;
}

.active-route {
    color: rgb(55, 126, 243) !important;
    font-weight: bold;
}

.route-layout {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
}

.vehicle-load div:not(:last-child) {
    border-right: none;
}

.vehicle-li {
    border-left: 2px solid #F7F7F7;
}

.vehicle-li div {
    width: 100%;
    padding: 8px 2px;
    font-size: 12px;
}


.vehicle-status {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 35% !important;
}

.vehicle-status img {
    width: 30px;
    margin-right: 6px;
}


.truck-row-alignment div {
    padding: 8px;
}


.vehicle-status div {
    font-size: 16px;
    padding: 0 4px;
}

.truck-row-alignment div:nth-child(1) {
    width: 35% !important;
    padding-left: 22px;
}


.truck-row-alignment div:nth-child(2) {
    width: 15% !important;
    padding-left: 12px;
}

.mtr-info-layout {
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
}

.mtr-info-layout:nth-child(odd) {
    background-color: #FAFAFA;
}


.mtr-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
}

.mtr-header .items {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mtr-header img {
    width: 22px;
}

.mtr-header .header-title {
    font-size: 15px;
    font-weight: bold;
    color: rgb(55, 126, 243);
}

.mtr-header .header-btn {
    font-size: 15px;
    cursor: pointer;
    color: rgb(55, 126, 243);
    text-decoration: underline;
}

.mtr-header .btn-icon {
    font-size: 20px;
    cursor: pointer;
    color: rgb(55, 126, 243);
}

.mtr-header .items > * {
    margin: 0 6px;
}

.info div {
    margin: 8px 0 8px 40px;
    font-size: 14px;
}

.info div:nth-child(1) {
    font-weight: bold;
}


.info-subtitle {
    align-items: center;
    margin: 14px 0;
}

.info-subtitle img {
    width: 22px;
    margin-right: 12px;
}

.info-subtitle div {
    font-size: 15px;
    font-weight: bold;
    color: rgb(55, 126, 243);
    letter-spacing: 0.1rem;
}

.info-plant_name {
    font-size: 15px;
    font-weight: bold;
    margin: 2px 0;
}

.info-date {
    font-size: 14px;
    font-weight: 500;
    margin: 2px 0;
}

.column.info-li {
    padding: 12px 36px;
    margin: 0 -36px;
}

.column.info-li:nth-child(odd) {
    background-color: #FAFAFA;
}

.info-message {
    padding: 4px 0;
    font-size: 15px;
    color: black;
    font-weight: 600;
}

.row.sub-info {
    padding: 4px 0;
    font-size: 14px;
    color: black;
    opacity: 0.8;
}

.row.sub-info div:nth-child(1) {
    margin-right: 12px;
}

.column.input-message {
    border: 1px solid rgb(0, 0, 0, 0.06);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 8px;
    margin-top: 20px;
}

.column.input-message textarea {
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
    font-weight: 500;
    font-size: 15px;
}

.row.info-action {
    justify-content: flex-end;
    margin-top: 8px;
}

.action-close {
    font-size: 15px;
    font-weight: bold;
    color: black;
    opacity: 0.7;
    margin-right: 8px;
}

.action-submit {
    font-weight: bold;
    font-size: 15px;
    color: rgb(55, 126, 243);
}

.row.info-action div {
    padding: 0 8px;
    cursor: pointer;
}

.btn-ng {
    height: 42px !important;
    background-color: white !important;
    color: rgb(55, 126, 243) !important;
    font-weight: bold !important;
    border: 2px solid rgb(55, 126, 243) !important;
    width: 140px !important;
}

.btn-ok {
    height: 42px !important;
    background-color: rgb(55, 126, 243) !important;
    color: white !important;
    font-weight: bold !important;
    width: 140px !important;
}

button.ant-modal-close {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: #606060;
    color: white !important;
    font-size: 16px;
}

.work input.ant-input {
    height: 42px;
}

.expand-height {
    height: calc(100vh - 330px) !important;
}

#components-select-demo-status .ant-select {
    margin: 0;
}

.ant-spin-spinning {
    position: absolute;
    left: calc(50% - 40px);
    top: calc(50% - 40px);
    z-index: 1001;
}

.spin-mask {
    background-color: rgb(0 0 0 / 17%)
}

.error-message {
    color: #ff4d4f;
}

.g-tb-order{
    grid-template-columns: [first] 15% [second] 15% [third] 20%
    [fourth] 25% [fifth] 30% [sixth] 10% [seventh] 10% [eigth] 10% [nineth] 10%
    [tenth] 10% [eleventh] 30% [twelveth] 15%;
    display: grid;
}
/*.order-tr div:nth-child(1), .order-td div:nth-child(1) {*/
/*    width: 15%;*/
/*}*/

/*.order-tr div:nth-child(2), .order-td div:nth-child(2) {*/
/*    width: 15%;*/
/*}*/

/*.order-tr div:nth-child(3), .order-td div:nth-child(3) {*/
/*    width: 20%;*/
/*}*/

/*.order-tr div:nth-child(4), .order-td div:nth-child(4) {*/
/*    width: 25%;*/
/*}*/

/*.order-tr div:nth-child(5), .order-td div:nth-child(5) {*/
/*    width: 30%;*/
/*}*/

/*.order-tr div:nth-child(6), .order-td div:nth-child(6) {*/
/*    width: 10%;*/
/*}*/

/*.order-tr div:nth-child(7), .order-td div:nth-child(7) {*/
/*    width: 10%;*/
/*}*/

/*.order-tr div:nth-child(8), .order-td div:nth-child(8) {*/
/*    width: 10%;*/
/*}*/

/*.order-tr div:nth-child(9), .order-td div:nth-child(9) {*/
/*    width: 10%;*/
/*}*/

/*.order-tr div:nth-child(10), .order-td div:nth-child(10) {*/
/*    width: 8%;*/
/*}*/

/*.order-tr div:nth-child(11), .order-td div:nth-child(11) {*/
/*    width: 30%;*/
/*}*/

/*.order-tr div:nth-child(12), .order-td div:nth-child(12) {*/
/*    width: 15%;*/
/*    padding-right: 22px;*/
/*}*/

/*.order-tr, .order-td {*/
/*    min-width: 1450px;*/
/*}*/

.order-td div, .order-tr {
    font-size: 12px;
}
.tb-h>div{
    font-size: 10px;
    font-weight: 600;
    color: rgb(0, 0, 0, 0.9);
    padding: 0 18px;
    line-height: 40px;
    height: 40px;
}
.tb-li>div{
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    padding: 0 18px;

}
.tb-li:nth-child(even) div{
    background-color: #FAFAFA
}


.mini-card-expand {
    max-height: unset;
    height: calc(100vh - 230px) !important;
}

#material_container {
    height: calc(100% - 50px);
}

.material-expand {
    width: 100%;
    height: calc(100vh - 230px) !important;
    max-height: unset;
    margin-right: 0;
}

.expand-table {
    height: calc(100vh - 343px) !important;
}

.leaflet-tooltip {
    background-color: transparent !important;
    border: none !important;
    color: blue !important;
    box-shadow: none !important;
}

.maintenance .table-li div{
    min-width: 160px;
    height: 44px;
    display: block;
    line-height: 32px;
}


.worker-list .table-header div{
    min-width: 160px;
}

.worker-list{

}

.li-selected{
    font-weight:600;
    color:#1890ff;
}
.li-selected div{
    background-color:#F0F1F3 !important;
}

.worker-list .li-width div:nth-child(4){
    min-width: 260px;
}
.worker-list .li-width div:last-child{
    min-width: 200px;
}

.worker-list .li-width div:nth-child(9),
.worker-list .li-width div:nth-child(10),
.worker-list .li-width div:nth-child(11),
.worker-list .li-width div:nth-child(12)
{
    min-width: 105px;
    display: flex;
    justify-content: center;
}
.g-worker{
    grid-template-columns: repeat(3, 160px) 260px repeat(4, 160px) repeat(4, 105px) 200px;
    display: grid;
}

.ant-checkbox-wrapper{
    padding: 5px 0;
}
.li-width div{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

}
.checkbox-role-layout{
    width: 50%;
}
.machine-layout{
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
.machine-layout img{
    width: 80px;
    height: auto;
}

.radio-item-layout{
    align-items: center;
}
.radio-layout .radio-item-layout{
    width: 50%;
}
.radio-title{
    margin: 0 0 0 6px;
    color: black;
    opacity: 0.8;
    font-size: 12px;
}
.notification-day{
    justify-content: space-between;
}
.question-time, .notification-time{
    width: 200px;
}
.small-select-layout{
    align-items: center;
}
.small-select-layout .label{
    margin-left: 12px;
    margin-right: 12px;
}

.site-menu-item.active{
    color:#1890ff;
}

.filter-layout{
    align-items: center;
    border: 1px solid #E9E9E9;
    border-radius: 6px;
    height: 40px;
}

.filter-layout .ant-picker{
    border: none !important;
    box-shadow: none;
    width: 220px;
    margin-top: 0;
    padding: 0 14px;
    font-size: 11px;
    margin-right: 12px;
}

.filter-layout .anticon-search{
    color: gray;
    font-size: 16px;
    margin-left: 20px;
    margin-right: 10px;
    cursor: pointer;
    padding: 4px;
}
.filter-layout .anticon-calendar{
    font-size: 15px;
}

.filter-layout .ant-picker-input > input:placeholder-shown{
    font-size: 11px;
}

.radio-doc label{
    margin: 15px 0;
}
.row.machine-layout img{
    opacity: 0.5;
    cursor: pointer;
}

.row.machine-layout .active{
    opacity: 1 !important;
}

.radio-layout .ant-radio-group{
    display: flex;
    width: 100%;
}
.notification-day .ant-checkbox-group{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

/*.management .table-header.li-width div{*/
/*    min-width: 160px;*/
/*}*/
.table-header.li-width div{
    min-width: 160px;
}
.row-active{
    color:#1890ff;
    font-weight: 600;
}
.row-active div{
    background-color: #F0F1F3 !important;
}

.g-vehicle-load{
    grid-template-columns: [first] 35% [last] auto;
    display: grid;
}

.g-vehicle-load img {
    width: 26px;
    margin-right: 6px;
}
.sbd>div:not(:last-child){
    border-right: 2px solid #F7F7F7 ;
}
.pointer{
    cursor: pointer;
}
.g-vehicle-load>div{
    padding: 8px 0;
    margin-left: 12px;
    margin-right: 4px;
}
.item-center{
    align-items: center;
}

.text-wrapper{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.striped>div:nth-child(even){
    background-color: #FAFAFA
}
.mini-content-wrapper{
    height: 256px;
    overflow-y: auto;
}
.f-mini-content-wrapper{
    height: 290px;
    overflow-y: auto;
}
.expand-content-wrapper{
    height: calc(100vh - 395px);
    overflow-y: auto;
}
.f-expand-content-wrapper{
    height: calc(100vh - 349px);
    overflow-y: auto;
}
.g-load-item{
    grid-template-columns: [first] 35% [unit] 80px [last] auto;
    display: grid;
}
.g-group{
    grid-template-columns:  repeat(2, 50%);
    display: grid;
}
.g-qualification{
    grid-template-columns: repeat(2, 50%);
    display: grid;
}
.g-user{
    grid-template-columns: [first] 15% [second] 15% [third] 15% [fourth] 15% [fifth] 20% [sixth] 20%;
    display: grid;
}
.g-site-management{
    grid-template-columns: [first] 25% [second] 25% [thrid] 25% [fourth] 25%;
    display: grid;
}
.g-heavy{
    grid-template-columns: repeat(6, 14%) 1fr;
    display: grid;
}
.g-management{
    grid-template-columns: repeat(14, 160px);
    display: grid;
}
.v-center{
    display: flex;
    align-items: center;
}
.g-work-content{
    grid-template-columns: 100%;
    display: grid;
}
/*.g-load-item>div{*/
/*    padding: 8px 0;*/
/*    margin-left: 12px;*/
/*    margin-right: 4px;*/
/*    line-height: 28px;*/

/*}*/