@media (max-width: 812px){


    .md{
        flex-direction: column;
    }

    .drawer-layout{
        position: absolute;
        z-index: 1001;
    }
    .load-item{
        margin: 16px 0;
    }

    .vehicle, .load-item, .travel-route, .location, .placement{
        width: 100%;
        margin-right: 0;
        height: 350px;
    }

    .placement{
        margin-top: 12px;
        height: 200px;
        min-height: 200px;
    }

}